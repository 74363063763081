.general-page-layout {
  .container {
    padding: 7rem 0;
    @media (max-width: 640px) {
      padding: 5rem 0 0 0;
      margin-bottom: 4rem;
    }
    &.smll{
      padding: 7rem 0 2rem 0;
      @media (max-width: 960px) {
        padding: 7rem 0 1rem 0;
      }
      &.form{
        padding: 2rem 0 7rem 0;
        @media (max-width: 960px) {
          padding: 2rem 0 2rem 0;
        }
      }
    }
    &.form{
      padding: 7rem 10% 7rem 10%;
      @media (max-width: 960px) {
        padding: 7rem 0 7rem 0;
      }
    }

    &.pt-0 {
      padding-top: 0;
    }

    &.pb-0 {
      padding-bottom: 0;
    }

    &.caution-wrap {
      padding: 0;
    }

    h2 {
      margin-bottom: 1.8rem;
    }
  }

  .hr {
    &.container {
      padding: 0;
    }
  }

  &.general-page-layout-bc0b8e5e-ae0a-4944-90e5-37035d8d079e,
  {
    .container {
      padding: 5rem 0;
    }
    .hr {
      &.container {
        padding: 0;
      }
    }
  }

  &.general-page-layout-7d7ee4a1-7417-44e6-b7b7-4eaabcd6b979{
    .container {
      &.text_and_image-6,
      &.text_and_image-7,
      &.text_and_image-8,
      &.text_and_image-9,
      &.text_and_image-11,{
      padding: 5rem 0;
      }
    }
  }

  // Faq
  &.general-page-layout-a9a87208-fc2f-4713-bad5-28ae47619c07 {
    .title_and_text-1 {
      padding-bottom: 0;
    }

    .title_and_text-3 {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.add-background-color {
  background: linear-gradient(to left, white 50%, #fee5f2 50%);

  .container {
    background-color: #fee5f2;
    position: relative;

    &:after {
      content: " ";
      height: 100%;
      width: 4.6vw;
      position: absolute;
      right: -4.6vw;
      top: 0;
      background-color: #fee5f2;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.add-background-color-reverse {
  background: linear-gradient(to right, white 50%, #fee5f2 50%);

  @media (max-width: 960px) {
    background: #fee5f2;
  }

  .container {
    background-color: #fee5f2;
    position: relative;

    &:before {
      content: " ";
      height: 100%;
      width: 4.6vw;
      position: absolute;
      left: -4.6vw;
      top: 0;
      background-color: #fee5f2;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }
}

.add-background-color-reverse,
.add-background-color {
  p {
    color: #20323F;
  }

  .wrapper {
    color: #20323F;
  }
}

.text_and_image {
  .img-wrap {}

  .txt-wrap {
    font-family: $standard-font-family;
    color: #787878;

    &.pad-left {
      padding-left: 2rem;

      @media (max-width: 960px) {
        padding-left: 0;
      }
    }
    ul{
      line-height: 2em;
      margin-bottom: 1.5rem;
      li{
        font-size: 1.05rem;
        @media (max-width: 1500px) {
          font-size: 1.3rem;
        }
        @media (max-width: 1100px) {
          font-size: 1.5rem;
        }
        @media (max-width: 960px) {
          font-size: 2rem;
        }
        @media (max-width: 768px) {
          font-size: 2.5rem;
        }
        @media (max-width: 640px) {
          font-size: 2rem;
        }
      }
    }


    &.pad-right {
      padding-right: 2rem;

      @media (max-width: 960px) {
        padding-right: 0;
      }
    }

    h1 {
      margin: 0 0 2.5rem;

      @media (max-width: 960px) {
        margin: 0 0 2.5rem;
        margin: 3rem 0 4.5rem;
      }
    }

    p {
      margin: 0 0 1.5rem;
    }
  }
}

.items {
  border-top: 2px solid rgba(223, 210, 216, 0.55);
  padding-top: 3rem;
  margin-top: 5rem;

  ul {
    margin-right: -65px;
    display: flex;

    @media (max-width: 840px) {
      display: block;
    }

    li {
      width: 33%;
      padding-right: 5rem;
      color: #20323f;
      line-height: 1.5;
      padding-left: 0;

      &::before{
        display: none;
      }

      @media (max-width: 840px) {
        width: 91%;
        padding-right: 0;
        margin-bottom: 7rem;
      }

      h5 {
        font-family: $title-font-family;
        font-weight: bold;
        font-size: 1.7rem;
        color: #20323f;
        line-height: 45px;
        margin-top: 20px;
        margin-bottom: 10px;

        @media (max-width: 960px) {
          font-size: 2.3rem;
        }
      }

      p {
        margin-bottom: 10px;
      }
    }
  }
}

.title_and_text {
  font-size: 20px;
  color: #787878;
  line-height: 1.5;
  padding-bottom: 20px;

  h1 {
    margin: 0 0 2rem;
  }

  p {
    margin: 0 0 2rem;
  }

  .btn-links {
    font-size: 16px;
  }
}

.checklist {
  color: #20323f;

  p {
    margin: 1.5rem 0 2.5rem;
  }

  .txt-wrap {
    width: 100%;

    p {
      max-width: 50%;

      @media (max-width: 1300px) {
        max-width: 80%;
      }
    }
  }

  ul {
    display: flex;
    margin-bottom: 50px;

    li {
      font-size: 0.9rem;
      line-height: 1.3rem;

      .tick {
        width: 17%;

        @media (max-width: 768px) {
          width: 10%;
        }

        @media (max-width: 640px) {}

        img {
          width: 75%;
        }
      }

      .checkdetail {
        width: 83%;
        padding-right: 6rem;
        padding-top: 0.1rem;

        @media (max-width: 1500px) {
          font-size: 1rem;
        }

        @media (max-width: 1300px) {
          font-size: 1.1rem;
          line-height: 1.4rem;
        }

        @media (max-width: 960px) {
          font-size: 1.6rem;
          line-height: 2.6rem;
        }

        @media (max-width: 768px) {
          font-size: 1.9rem;
          line-height: 2.8rem;
        }

        @media (max-width: 640px) {
          font-size: 1.8rem;
        }
      }
    }
  }
}


.snippet-global {
  .txt-wrap {}

  .img-wrap {}

  ul {
    margin: 1rem 0;

    li {

      .number {
        @apply font-bold;
        border-radius: 50%;
        overflow: hidden;
        color: #20323f;
        text-align: center;
        font-family: $title-font-family;
        width: 3rem;
        height: 3rem;
        border: .2rem #20323f solid;
        line-height: 2.6rem;
        font-size: 1.6rem;

        @media (max-width: 1300px) {
          width: 4rem;
          height: 4rem;
          border: .25rem #20323f solid;
          line-height: 3.6rem;
          font-size: 2rem;
        }

        @media (max-width: 960px) {
          width: 6rem;
          height: 6rem;
          border: .5rem #20323f solid;
          line-height: 5.2rem;
          font-size: 4rem;
        }

        @media (max-width: 768px) {
          width: 4rem;
          height: 4rem;
          border: .6rem #20323f solid;
          line-height: 3.3rem;
          font-size: 2rem;
        }

        @media (max-width: 640px) {
          width: 4rem;
          height: 4rem;
          border: .4rem #20323f solid;
          line-height: 3.3rem;
          font-size: 2rem;
        }
      }

      .icon {}

      h4 {}
    }
  }

  // help when you need it
  &.snippet-9a47d539-1c06-4001-82f2-fcfa89ce525b{
    h2{
      display: none;
    }
  }



  &.snippet-4613529b-8e0b-4a66-ba63-d1ed54aad432,
  &.snippet-9a47d539-1c06-4001-82f2-fcfa89ce525b,
  &.snippet-f31e1062-0b52-41f5-8af6-b5177e323d46 
   {

    ul {
      margin: 4rem 0 2rem;

      li {
        display: flex;
        padding-right: 6rem;

        @media (max-width: 960px) {
          border-bottom: 1px solid #dfd2d8;
          padding-bottom: 6rem;
          margin-bottom: 6rem;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 3rem;
          }
        }


        .wrapper {
          display: flex;
          flex-direction: column;
        }

        h4 {
          @apply text-pink;
          font-family: $title-font-family;
          font-size: 1.35rem;
          margin-bottom: .8rem;

          @media (max-width: 1500px) {
            font-size: 1.7rem;
          }

          @media (max-width: 960px) {
            font-size: 3.8rem;
          }

          @media (max-width: 640px) {
            font-size: 2.8rem;
          }
        }

        .icon {}

        .img-wrap {
          width: 100%;
          padding-top: 20px;
          padding-bottom: 30px;

          img {
            height: 8rem;

            @media (max-width: 960px) {
              height: 20rem;
            }

            @media (max-width: 640px) {
              height: 10rem;
            }
          }
        }
      }
    }
  }

  // Professional CTA
  &.snippet-9a47d539-1c06-4001-82f2-fcfa89ce525b {
    .container {
      padding: 0;
      padding-bottom: 3rem;
    }
  }

  // Home smart hub & personal pendant
  &.snippet-f182f0c3-c750-4f38-8c48-43ae62351d77, &.snippet-97ebfb89-a86c-4136-b6c1-1b97b701392b {

    .container {
      padding: 8rem 0;
      @media (max-width: 960px) {
        padding: 6rem 0 2rem;
      }
    }

    .items-center {
      @media (max-width: 960px) {
        position: relative;
      }
    }

    h2 {
      max-width: 83%;

      @media (max-width: 960px) {
        max-width: 100%;
        width: 100%;
      }
    }

    p {
      max-width: 87%;

      @media (max-width: 960px) {
        max-width: 100%;
        width: 100%;
      }
    }

    .hubimage {
      right: -4rem;
      position: relative;

      @media (max-width: 960px) {
        display: none;
      }

      img {
        @media (min-width: 960px) and (max-width: 1250px) {
          max-width: 140%;
        }

      }

    }

    ul {
      margin: 4.2rem -2rem;

      li {
        background: #ffffff;
        position: relative;
        box-shadow: 0 0 7px -1px rgba(0, 0, 0, 0.35);
        border-radius: 0.6rem;
        margin: .5rem;
        width: calc(25% - 1.4rem);
        padding: 1rem;
        font-family: $title-font-family;
        text-align: center;
        color: #20323f;

        @media (max-width: 1250px) {
          width: 84%;
          padding: .8rem 1.2rem .8rem 1.8rem;
          margin: .7rem;
        }

        @media (max-width: 960px) {
          width: 100%;
          padding: 3rem 1.2rem 3rem 3.5rem;
          margin: 1rem;
        }


        .wrapper {
          @media (max-width: 1250px) {
            display: flex;
          }
        }

        .title-wrap {
          @media (max-width: 1250px) {
            justify-content: flex-start;
            display: flex;
            width: 83%;
          }

          @media (max-width: 960px) {
            width: 88%;
          }

          @media (max-width: 640px) {
            width: 73%;
          }
        }

        .icon {
          position: absolute;
          left: calc(50% - 1.2rem);
          top: -1.2rem;
          background-color: white;
          border-radius: 50%;
          overflow: hidden;

          @media (max-width: 1250px) {
            left: -1.3rem;
            top: 1.1rem;
          }

          @media (max-width: 960px) {
            top: 2.2rem;
            left: -2.8rem;
          }

          @media (max-width: 640px) {
            left: -1.8rem;
          }

          img {
            width: 2.6rem;

            @media (max-width: 960px) {
              height: 5.6rem;
              width: 5.6rem;
            }
          }
        }

        .img-wrap {
          height: 5.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;

          @media (min-width: 960px) and (max-width: 1250px) {
            height: 3.4rem;

          }

          @media (max-width: 960px) {
            height: 4.4rem;
          }

          img {
            height: 3.2rem;

            @media (min-width: 960px) and (max-width: 1250px) {
              height: 2.2rem;
            }

            @media (max-width: 960px) {
              height: 4.2rem;
            }
          }
        }

        h4 {
          font-size: .9rem;

          @media (min-width: 960px) and (max-width: 1250px) {
            font-size: 1.4rem;
          }

          @media (max-width: 960px) {
            font-size: 2rem;
          }
        }
      }
    }
  }

  // we love our customers
  &.snippet-1024ab3b-5d7b-4d54-9e30-78be6fe1f228 {}

  // who we work with
  &.snippet-46fdd055-d0fa-43ef-947f-3bb16473e888 {
    ul {
      display: flex;
      border: 1px #e7dada solid;
      flex-wrap: nowrap;
      @media (max-width: 960px) {
        flex-wrap: wrap;
      }
      li {
        width: 20%;
        border: 1px #dadada solid;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 12rem;
        background-color: white;
        @media (max-width: 960px) {
          width: 33.3333%;
          height: 24rem;
        }
        @media (max-width: 768px) {
          width: 50%;
        }
        @media (max-width: 640px) {
          height: 16rem;
        }


        .title-wrap {
          display: none;
        }

        img {
          margin: 0 auto;
          max-width: 8rem;
          vertical-align: middle;
          @media (max-width: 960px) {
            max-width: 14rem;
          }
        }
      }
    }
  }
}



ul {
  li.stack-item {
    font-family: $title-font-family;
    border-top: 1px solid #dfd2d8;
    padding: 12px 0;
    font-size: 1.5rem;
    max-width: 365px;
    @media (max-width: 960px) {
      max-width: 100%;
      font-size: 2.5rem;
    }
    img {
      @apply inline-block relative;
      margin-left: 15px;
      top: -2px;
    }

    a {
      @apply text-pink;
    }

    &:nth-child(1) {
      border-top: 0;
    }
  }
}

ul.feature-items {
  li {
    .img-wrap {
      @apply flex text-center items-center;
      padding: 2.5rem 0 0;
      height: 167px;

      div {
        @apply w-full;
        padding: 0 2.5rem;
      }

      img {
        margin: 0;
        display: inline-block;
        max-height: 167px;
      }
    }

    .img-wrap-cover {
      height: 0;
      padding-top: 56%;
      overflow: hidden;
      width: 100%;
      position: relative;

      img {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }
  &.case-studies{
    li{
      .img-wrap-cover{
        padding-top: 76%;
      }
    }
  }
}

.caution {
  background-color: #fee5f2;
  border-radius: 1rem;
  padding: 2rem 4rem;
  @media (max-width: 960px) {
    padding: 4rem;
  }

  img {}

  p {

  }
}

.step-styles {
  padding: 6px 0px;
  width: 100px;
  color: #ffffff;
  text-align: center;
  background: #e72076;
  border-radius: 10px;
  margin-bottom: 20px;

  .step {
    font-size: 14px;
    margin: 0 !important;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  height: 0;

  &.paused-state {
    .play {
      display: flex;
    }
  }
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: -1px;
  width: 100%;
  height: 100%;
}

.accordion {
  li {
    .wrapper {
      background: #fee5f2;
      position: relative;
      padding: 2.2rem 0;
      @media (max-width: 960px) {
        padding: 2.2rem 2rem;
      }
      @media (max-width: 768px) {

      }
      @media (max-width: 640px) {

      }

      &:before,
      &:after {
        @apply inline-block absolute;
        background: #fee5f2;
        height: 100%;
        content: " ";
        width: 2rem;
        position: absolute;
        top: 0;
      }

      &:before {
        left: -2rem;
        border-top-left-radius: .8rem;
        border-bottom-left-radius: .8rem;
      }

      &:after {
        right: -2rem;
        border-top-right-radius: .8rem;
        border-bottom-right-radius: .8rem;
      }

      .toggle {
        display: none;
      }

      .title {
        @apply relative;
        font-family: $title-font-family;
        color: #e72378;
        cursor: pointer;
        backface-visibility: hidden;
        transform: translateZ(0);
        transition: all 0.2s;
        font-size: 1.5rem;
        line-height: 1.3;
        @media (max-width: 1024px) {
          padding: 1.9rem 0;
        }
        @media (max-width: 960px) {
          font-size: 2.2rem;
        }
        @media (max-width: 768px) {
          font-size: 2.5rem;
          line-height: 3.8rem;
        }
        @media (max-width: 640px) {
          font-size: 2rem;
        }


        img {
          transition: all 0.2s;
        }

        .accordian-arrow{
          width: 30px;
          max-width: 30px;
          padding-top: 0.5rem;
          @media (max-width: 1024px) {
            width: 26px;
            max-width: 26px;
            padding-top: 0.2rem;
          }
          @media (max-width: 960px) {
            width: 23px;
            max-width: 23px;
            padding-top: 0.8rem;
          }
          @media (max-width: 640px) {
            padding-top: 1.2rem;
          }
        }

        &:active {
          top: 1px;
        }
      }


      .response {
        font-size: 20px;
        color: #787878;
        line-height: 1.45;
        backface-visibility: hidden;
        transform: translateZ(0);
        transition: all 0.2s;
        max-height: 0;
        overflow: hidden;
      }

      .toggle:checked {

        &+.title,
        &+.title+.response {}

        &+.title+.response {
          max-height: 500px;
        }

        &+.title img {
          transform: rotate(180deg);
        }
      }
    }
  }
}

// go to links gifferent grids.
.general-page-layout-274b0f71-9dfd-4241-b32a-f54cb0a071a3{
  .go_to_links{

  }
}

// How it works page
.general-page-layout-7d7ee4a1-7417-44e6-b7b7-4eaabcd6b979{
  .title_and_text-5{
    padding: 5rem 0 0 0;
    h1{
      color: #e72076;
    }
  }
}

// Order Now Page
.general-page-layout-15634879-fccb-4124-9e84-be9fb52b1085{
  .title_and_text-1.container {
    padding: 5rem 0 1rem;
  }

  .product {
    color: #787878;
    line-height: 1.5;
    padding: 5rem 0 5rem 0;
    position: relative;
    @media (max-width: 768px) {
      padding: 5rem 0 1rem 0;
    }
    @media (max-width: 960px) {
      padding: 7rem;
      border-radius: 2rem;
    }
    @media (max-width: 640px) {
      padding: 3rem;
      border-radius: 2rem;
    }

  }
}


// Additional Sensors snippet
.snippet-a93e5e88-1629-47f4-8813-dd4c1a956e9f{
  .additionalsensor-text{
    order: 2;
    @media (max-width: 960px) {
      order: 1;
      padding: 0 10%;
    }
  }
  .additionalsensor-image{
    order: 1;
    @media (max-width: 960px) {
      display: none;
    }
  }

  .container{
    width: 100%;
    max-width: 100%;
    padding: 6rem 0 8rem;
  }

}

// remove the how it works button on the how it works page
.general-page-layout-7d7ee4a1-7417-44e6-b7b7-4eaabcd6b979 .snippet-4613529b-8e0b-4a66-ba63-d1ed54aad432{
  .btn{
    display: none;
  }
}

.snippet-41637f99-071b-401c-bdaa-423fb3d5d54d{
  .txt-wrap{
    p{
      margin: 0 0 1.5rem;
    }
  }
}
// pacsana landing page
.general-page-layout-c51b499a-6cb5-4663-a408-68140082d0d5{
  .checklist{
    .container{
      padding: 4rem 0 1.5rem 0;
    }
  }

}
