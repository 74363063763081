.privacy-area{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 8999;
  color: #fff;
  background: rgba(0, 0, 0, 0.43);
  opacity: 1;
  transition: all .3s ease;
  .privacy-content{
    max-width: 550px;
    top: 0;
    left: 0;
    width: 90%;
    overflow: scroll;
    background: #386791;
    height: 100vh;
    padding: 5% 3% 100px;
    @media (max-width: 1024px) {
      padding: 5% 4.5% 100px;
    }
    .privacy-section{
      border-bottom: 1px solid #637f99;
      padding: 1.5rem 0 .5rem 0;
      h2{
        @apply .text-white;
        font-size: 1.3rem;
        font-weight: 700;
        margin-bottom: .8rem;
        @media (max-width: 1500px) {
          font-size: 23px;
        }

      }
      p{
        font-size: .9rem;
        @media (max-width: 1500px) {
          font-size: 15px;
        }
        @media (max-width: 900px) {
          margin-bottom: 26px;
        }
      }
      &:first-child{
        padding: 0 0 .5rem 0;
      }
    }

  }
  &.hidden{
    opacity: 0;
  }
}
.cookie-icon{
  position: fixed;
  cursor: pointer;
  bottom: 0;
  left: 0;
  z-index: 9999;;
}
