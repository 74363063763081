header {
  background-color: white;
  position: relative;
  z-index: 1000;
  .navinner {
    padding: 2.5rem 0;
    @media (max-width: 960px) {
      padding: 4.5rem 0;
    }
    @media (max-width: 640px) {
      padding: 3.5rem 0;
    }
  }
  .logo {
    width: 100%;
    img {

      width: 67%;
      @media (max-width: 1250px) {
        width: 70%;
      }
      @media (max-width: 960px) {
        width: 46%;
      }
    }
  }
  ul {
    li {
      font-family: $title-font-family;
      &:last-child {
        margin-left: 0;
      }

      @media (max-width: 1400px) {


      }

      @media (max-width: 1255px) {
        &:nth-child(4) {
          display: none;
        }
      }

      @media (max-width: 1140px) {
        &:nth-child(3) {
          display: none;
        }
      }

      a {
        font-size: 0.8rem;
        @media (max-width: 1750px) {
          font-size: 0.77vw;
        }
        @media (max-width: 1450px) {
          font-size: 0.76vw;
        }
        @media (max-width: 1390px) {
          font-size: 0.74vw;
        }
        @media (max-width: 1133px) {
          font-size: 0.92vw;
        }
        &[href="/order-now"] {
          background-color: #e72076;
          color: white;
          border-radius: 100px;
          padding: 0.75rem 1.3rem;
        }
        &[href="/pay-a-bill"] {
          background-color: #20323f;
          color: white;
          border-radius: 100px;
          padding: 0.75rem 1.3rem;
        }
      }
      i {
        top: -3px;
      }
      .subMenu {
        padding: 10px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.28);
        right: -169px;
        top: 3.4rem;
        padding: 25px;
        width: 340px;
        border-radius: 10px;
        &:before {
          position: absolute;
          content: " ";
          display: inline-block;
          width: 16px;
          height: 16px;
          left: calc(50% - 11.5px);
          top: -8px;
          z-index: 100;
          background: white;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.28);
          transform: rotate(45deg);
        }
        &:after {
          content: " ";
          position: absolute;
          width: 50px;
          height: 25px;
          z-index: 150;
          top: 0;
          left: calc(50% - 25px);
          background: white;
        }
        h4 {
          a {
            font-size: 1.1rem;
            @media (max-width: 1400px) {
              font-size: 1.3rem;
            }
            @media (max-width: 1250px) {
              font-size: 1.5rem;
            }
          }
        }
        ul {
          margin-top: 10px;
          li {
            border-top: 1px solid #dcdcdc;
            padding: 10px 0;
            font-family: $standard-font-family;
            &.viewall {
              display: block;
              text-decoration: underline;
            }
            &:nth-child(1) {
              border-top: 0;
            }
            &:last-child {
              a {
                color: #e72076;
              }
            }
            a {
              font-size: 1rem;
              color: #20323f;

              @media (max-width: 1250px) {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
  .social{
    @apply .justify-end ;
    padding-top: .2rem;
    @media (max-width: 1030px) {
      display: none;
    }
    .facebook{
    }
    .twitter{

    }
    .insta{

    }

  }
}

.mobile-burger-btn {
  cursor: pointer;
  margin: 0;
  text-align: center;
  transition: right 0.5s;
  z-index: 100;
  border-radius: 3rem;
  display: inline-block;
  position: absolute;
  right: 7.5%;
  top: 3.5rem;
  z-index: 9999;
  @media (max-width: 1250px) {
    top: 3.7rem;
  }
  @media (max-width: 960px) {
    top: 5.7rem;
  }
  @media (max-width: 640px) {
    top: 4rem;
    right: 9.5%;
  }
  i {
    font-size: 22px;
    position: relative;
    color: #1d3248;
    @media (max-width: 960px) {
      background-color: #e72076;
      border-radius: 4rem;
      color: #fff;
      display: flex;
      padding: 10px 20px;
      text-transform: uppercase;
    }
    &:nth-child(2) {
      display: none;
    }
    b {
      display: none;
      font-family: $title-font-family;
      font-size: 16px;
      padding: 4px 0 2px 10px;
      @media (max-width: 960px) {
        display: block;
      }
    }
  }
  &.menu-open {
    right: 5%;
    position: fixed;
    @media (max-width: 600px) {
      right: 9.5%;
    }
    i {
      color: #ffffff;
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: inline-block;
      }
    }
  }
}

#popup-warninginwindow{
  p{
    color: white;
    
  }
}
