.products-container {
  margin: 4rem 0;
  @media (max-width: 640px) {
    margin: 1rem 0;
  }

  .product {

    color: #787878;
    line-height: 1.5;
    padding: 5rem 0 5rem 0;
    position: relative;
    @media (max-width: 960px) {
      width: 80%;
    }
    @media (max-width: 640px) {
      width: 77%;
    }

    &.product-bg {
      background-color: #fee5f2;

      &:before {
        content: " ";
        height: 100%;
        width: 90px;
        position: absolute;
        left: -90px;
        top: 0;
        background-color: #fee5f2;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        @media (max-width: 960px) {
          width: 40px;
          left: -40px;
        }
      }

      &:after {
        content: " ";
        height: 100%;
        width: 90px;
        position: absolute;
        right: -90px;
        top: 0;
        background-color: #fee5f2;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        @media (max-width: 960px) {
          width: 40px;
          right: -40px;
        }
      }
    }

    .product-details{
      display: flex;
      align-items: center;
      @media (max-width: 960px) {
        flex-direction: column;
      }
      .price-column{
        display: flex;
        @media (max-width: 960px) {
          margin-top: 5rem;
        }
        @media (max-width: 640px) {
          flex-direction: column;
        }
      }
      .pro_img{
        background-color: white;
        padding: 3rem;
        margin-bottom: 2rem;
        border-radius: 14px;
      }
    }


    h2.productTitle {
      font-family: $title-font-family;
      font-size: 3.4rem;
      color: #20323f;
      line-height: 4rem;
    }

    ul.pricing-details {
      &>li {
        background: #ffffff;
        box-shadow: 0 0 7px -1px rgba(0, 0, 0, 0.35);
        border-radius: 20px;
        padding: 50px;
        margin: 0 20px;
      }
    }
  }
}

.product-half-col{
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    justify-content: space-between;
  }
  @media (max-width: 1020px) {
    flex-direction: column;
  }
  .products-container{
    width: 50%;
    @media (max-width: 1200px) {
      width: 48%;
    }
    @media (max-width: 1020px) {
      width: 100%;
    }
    .product {
      padding: 3rem 0 3rem 0;
      &.product-bg {
        background-color: #fee5f2;

        &:before {
          width: 40px;
          left: -40px;

        }

        &:after {
          width: 40px;
          right: -40px;

        }
      }
      .product-details{
        display: flex;
        align-items: center;
        flex-direction: column;
        .price-column{
          @apply .-mr-4 .mt-0 .-mx-3;
          display: flex;
          &.small{
            @apply .mx-0;
            margin-left: -1rem;
            margin-right: -1rem;
            @media (max-width: 640px) {
              flex-direction: column;
              margin-left: 0;
              margin-right: 0;
            }

          }
        }
        h2, h3{
          text-align: center;
          line-height: 1;
        }
        h2{
          font-size: 2rem;
          line-height: 1.3;
          padding: 0 3rem;
          min-height: 5.5rem;
          @media (max-width: 1020px) {
            font-size: 3rem;
          }
          @media (max-width: 960px) {
            font-size: 4rem;
          }
          @media (max-width: 640px) {
            font-size: 3rem;
          }
        }
        h3{
          font-size: 1.4rem;
          @media (max-width: 1020px) {
            font-size: 2.4rem;
          }
          @media (max-width: 960px) {
            font-size: 3.4rem;
          }
          @media (max-width: 640px) {
            font-size: 2.4rem;
          }
        }

        p{
          padding: 2rem 2rem 0 2rem;
          font-size: 1rem;
          text-align: center;
          @media (max-width: 1020px) {
            font-size: 1.5rem;
            line-height: 1.5;
          }
          @media (max-width: 960px) {
            font-size: 2rem;
          }
        }

        .pricing-panel-minor {
          height: auto;
          @media (max-width: 960px) {
            margin-bottom: 1rem;
          }
          @media (max-width: 640px) {
            font-size: 2.4rem;
            &:first-child{
              margin-bottom: 6rem;
            }
          }
          ul {
            padding: 0 0 1.2rem 0;
            margin: 0;
            li {
                color: #20323f;
                margin: .2rem 0;
            }
          }
          h4 {
            font-size: 2.4rem;
            @media (max-width: 960px) {
                font-size: 4.4rem;
            }
          }
          h3 {
            font-size: 1.2rem;
            @media (max-width: 960px) {
              font-size: 2rem;
            }
          }
          .btm {
            padding: 1.2rem 0 0 0;
          }
        }
      }

    }
  }
}
