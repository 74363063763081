.case-studies-content {

  h2,
  h3,
  h4 {
    @apply font-bold;
    font-family: $title-font-family;
    font-size: 30px;
    line-height: 1.3;
    color: #20323f;
    margin-bottom: 1.2rem;
  }
  p {
    margin-bottom: 1.5em;
    &:first-of-type{
      color: #20323f;
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 600;
      @media (max-width: 960px) {
        font-size: 2.8rem;
        line-height: 3.7rem;
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0 0 1.5em;
    padding: 0;

    li {
      width: 100%;
      padding-left: 50px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin: 10px 0;
      background-image: url("/assets/tick-li.png");
      background-position: left center;
      background-repeat: no-repeat;
      font-size: 1.05rem;
      @media (max-width: 1500px) {
        font-size: 1.3rem;
      }
      @media (max-width: 1100px) {
        font-size: 1.5rem;
      }
      @media (max-width: 960px) {
        font-size: 2rem;
      }
      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
      @media (max-width: 640px) {
        font-size: 2rem;
      }
    }
  }


}

.mini-list {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      width: 100%;
      padding-left: 50px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin: 10px 0;
      background-image: url("/assets/tick-li.png");
      background-position: left center;
      background-repeat: no-repeat;
      font-size: 1.05rem;
      @media (max-width: 1500px) {
        font-size: 1.3rem;
      }
      @media (max-width: 1100px) {
        font-size: 1.5rem;
      }
      @media (max-width: 960px) {
        font-size: 2rem;
      }
      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
      @media (max-width: 640px) {
        font-size: 2rem;
      }
    }
  }
}

.case-study {
  ul {
    li {
      border-radius: 1rem;
      overflow: hidden;
      .img-wrap {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 30px;
        min-height: 300px;

        img {
          height: 100%;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
      }
    }
  }
}

.category {
  width: fit-content;
  border-radius: 7.5px;
  text-align: center;
  padding: 5px 20px;
}

.case-study-small-img {
  position: relative;
  top: -3rem;
  @media (max-width: 960px) {
    top: -5rem;
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }

  .round-img {
    border-radius: 100%;
  }
}

.slick-slide {
  &:focus {
    outline: none;
  }
}

.case-study-item {
  .customer-testimonials {
    background: #fee5f2;
    border-radius: 10px;
    height: fit-content;
    min-height: 350px;
    padding: 0 6%;
    width: 90%;
    margin: 5rem 5%;

    p {
      padding: 0 30px 0 30px;
    }
  }

  .slick-active {
    .customer-testimonials {
      background: #ffffff;
      box-shadow: 0 4px 12px 2px #ffeaea;
    }
  }
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5rem;
  @media (max-width: 640px) {
    margin-bottom: 10rem;
  }

  .testimonialpagination {
    width: fit-content;

    ul.slick-dots {
      position: relative;
      bottom: 4px;
      line-height: 0;
      padding: 0 15px;
      margin: 0;
      li {
        padding: 0;
        margin: 0;
        display: inline-block;
        position: relative;
        background: none;

        button {
          position: absolute;
          top: 6px;
          left: 6px;
          height: 1px;
          width: 1px;
          border-radius: 50%;
          overflow: hidden;
          border: none;
          background: #20323f;
          margin: 0;
          padding: 5px;
          cursor: pointer;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          button {
            background: #e72076;
          }
        }
      }
    }
  }

  #nxt-btn,
  #prev-btn {
    width: auto;
    height: 20px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    color: #616161;

    .fa-arrow-left,
    .fa-arrow-right {
      &::before {
        font-size: 22px;
      }
    }
  }
}
