.form-wrapper {
  label {
    @apply block font-bold;
    font-family: $title-font-family;
    color: #20323f;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    @media (max-width: 960px) {
      margin-top: 2.75rem;
      margin-bottom: 1.3rem;
    }
    .required {
      color: #ad0c0c;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    -webkit-appearance: none;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    @apply bg-white block w-full mb-5;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 1.1rem;
    color: #000;
    @media (max-width: 960px) {
      padding: 1.6rem;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.3);
  }

  input[type="datetime-local"] {
    @apply bg-white block w-full mb-5;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 1.1rem;
    color: #000;
  }

  textarea {
    min-height: 184px;
    padding: 22.5px;
  }

  .helper-txt {

    font-size: 1rem;
    color: #787878;
    line-height: 1.5;
    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }
    @media (max-width: 768px) {
      font-size: 2rem;
    }
    @media (max-width: 640px) {
      font-size: 1rem;
    }
  }

  .terms-wrap-chk {
    @apply my-10;
    font-weight: 600;
    font-size: 14px;

    input {
      @apply mr-2;
      font-size: 30px;
    }
  }

  .chk-lbl {
    font-weight: 600;
    font-size: 14px;
    color: #4a4a4a;
    padding-left: 15px;
  }

  button.btn {
    @apply font-bold;
  }


.amount-wrapper {
  background-color: #fff;
  width: 100%;

  box-shadow: 0 0 4px 0 rgba(0,0,0,0.3);
  border-radius: 5px;
  padding: 0;
  color: #000;
  overflow: hidden;
  .select_style {
    border-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width:240px;
    select {
      padding: 1rem;
      font-size: 17px;
      color: #a0aec0;
    }
    &.full {
      width: 100% ;
    
    }
  }
  input {
    border:0;
    margin:0;
    box-shadow: none;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
}


.order-wrapper {
  background-color: #fff;
  width: 100%;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.3);
  border-radius: 5px;
  padding: 0;
  color: rgb(210, 210, 210);
  overflow: hidden;
  .select_style {
    border-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    select {
      padding: 1rem;
      font-size: 17px;
      color: #a0aec0;
    }
    &.full {
      width: 100% ;
    }
  }
  input {
    border:0;
    margin:0;
    box-shadow: none;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

}

.validation-msg {
  @apply bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-3;
}


.select_style {
  position: relative;
  select {
    width: 100%;
    height:100%;
    font-size: 18px;
    border: 0;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    font-size: 18px;
    padding-right:36px !important;
  }
  &:after {
    position: absolute;
    border-color: #333 transparent transparent transparent;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    content: "";
    height: 0;
    pointer-events:none;
    right: 15px;
    top: calc(50% - 4px);
    width: 0;
  }
}


.fancybox-content {
  width: 90%;
  margin: 0 auto;
  max-width: 600px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.27);
  .fancybox-button {
    background: none;
    color: #bbb;
    outline: none;
    position: relative;
    &:active {
      top: 1px;
    }
    &:hover {
      @apply text-pink;
    }
  }
}

.three-d-secure-frm {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(#000, 0.7);
  display:flex;
  justify-content:center;
  align-items:center;
  z-index: 1000;
  &.hidden {
    display: none;
  }
  iframe {
    width:90vw;
    max-width:400px;
    height:425px;
    background-color: white;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
