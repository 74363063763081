.btn-links {
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}


.btn {
  @apply bg-pink text-white uppercase font-bold inline-block relative;
  border-radius: 100px;
  padding: .75rem 1.6rem;
  @media (max-width: 768px) {
    padding: 1.2rem 2.9rem;
  }
  &:active {
    top:1px;
  }
  &[data-selected="false"] {
    @apply bg-lightgrey text-black
  }
}

.secondary-btn {
  @apply lowercase underline text-navy font-bold;
  @media (max-width: 600px) {
    padding: 2rem 0;
  }
}

.btn-optional {
  @extend .btn;
  background-color: #d8d8d8;
  color: #4a4a4a;
  cursor: pointer;
  padding: 1rem 1.6rem;
}


.secondary-btn, .btn-optional, .btn{
  font-size: .85rem;
  @media (max-width: 1300px) {
    font-size: 1rem;
  }
  @media (max-width: 1025px) {
    font-size: 1.2rem;
  }
  @media (max-width: 1000px) {
    font-size: 1rem;
  }
  @media (max-width: 960px) {
    font-size: 1.6rem;
  }
  @media (max-width: 768px) {
    font-size: 2.1rem;
  }
  @media (max-width: 640px) {
    font-size: 1.3rem;
  }
}

.link-text{
  font-size: 1rem;
  @media (max-width: 1300px) {
    font-size: 1.4rem;
  }
  @media (max-width: 960px) {
    font-size: 2rem;
  }
}

.icon-size{
  @apply .overflow-visible;
  width: 2.2rem;
  height: 2.2rem;
  @media (max-width: 900px) {
    width: 4rem;
    height: 4rem;
  }
  @media (max-width: 700px) {
    width: 6rem;
    height: 6rem;
  }
}
