footer {
  font-family: $title-font-family;
  padding-top: 8rem;
  color: #20323f;

  .footer-left-inner{
    @media (max-width: 1300px) {
      padding-right: 4rem;
    }
  }

  .footer-right-inner{
    padding-left: 7rem;
    @media (max-width: 1500px) {
      padding-left: 5rem;
    }
    @media (max-width: 1300px) {
      padding-left: 0;
    }
    @media (max-width: 960px) {
      padding-top: 3rem;
    }
  }

  h5 {
    @apply font-bold;
    font-size: 4.45rem;
    color: #20323f;
    line-height: 1.1;
    @media (max-width: 1300px) {
      font-size: 4rem;
    }
    @media (max-width: 960px) {
      padding-top: 2rem;
    }
    @media (max-width: 768px){
      font-size: 6rem;
      line-height: 6.5rem;
    }

    @media (max-width: 640px) {
      font-size: 5rem;
      line-height: 5.5rem;
    }

  }
  p {
    @apply font-bold;
    font-size: 1.06rem;
    @media (max-width: 960px) {
      font-size: 1.8rem;
    }
    @media (max-width: 768px) {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }
    @media (max-width: 640px) {
      font-size: 1.9rem;
      line-height: 2.5rem;
    }
  }
  h4 {
    @apply font-bold;
    font-size: .95rem;
    @media (max-width: 1500px) {
      font-size: 1.2rem;
    }
    @media (max-width: 1300px) {
      font-size: 1.3rem;
    }
    @media (max-width: 960px) {
      font-size: 1.8rem;
    }
    @media (max-width: 768px) {
      font-size: 2.2rem;
    }
  }
  ul {
    li {
      font-family: $standard-font-family;
      font-weight: 300;
      font-size: .88rem;
      line-height: 1.5rem;
      color: #20323f;
      @media (max-width: 1500px) {
        font-size: 1rem;
      }
      @media (max-width: 1300px) {
        font-size: 1.2rem;
        line-height: 1.7rem;
      }
      @media (max-width: 960px) {
        font-size: 1.5rem;
        line-height: 2.5rem;
      }
      @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
    }
  }
  .top {
    padding-bottom: 8rem;
  }
  .btm {
    padding: 3.5rem 0;
    border-top: 2px #d7d7d7 solid;
    font-family: $standard-font-family;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5rem;
    @media (max-width: 1300px) {
      font-size: 1.3rem;
      line-height: 2.5rem;
    }
    @media (max-width: 1000px) {
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
    @media (max-width: 768px) {
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
    @media (max-width: 640px) {
      font-size: 1.5rem;
      line-height: 3rem;
    }

  }
}
