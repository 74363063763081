.ordernow-outter{
  @apply .flex .-mx-12;
  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.payment-form{
  width: 63%;
  @media (max-width: 1200px) {
    width: 55%;
  }
  @media (max-width: 960px) {
    width: 80%;
    margin: 0 auto;
  }
  .form-item{
    label {
      margin-top: .5rem;
    }
  }

  .halfform{
    @apply .flex .flex-wrap .-mx-3;
    @media (max-width: 960px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.payment-details {
  width: 37%;
  border-radius: 1rem;
  background: #fee5f2;
  padding: 3.6rem;
  height: fit-content;
  @media (max-width: 1200px) {
    width: 45%;
  }
  @media (max-width: 960px) {
    width: 80%;
    margin: 0 auto;
  }
  @media (max-width: 640px) {
    width: 90%;
    margin: 0 auto;
  }
  h2 {
    @apply font-bold;
    font-family: $title-font-family;
    color: #e72076;
    font-size: 1.5rem;
    line-height: 1;
    @media (max-width: 960px) {
      font-size: 3.5rem;
      line-height: 1.1;
      margin-bottom: 4rem;
    }
  }

  h1 {
    @apply font-bold;
    font-family: $title-font-family;
    color: #20323f;
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 2rem;
    line-height: 1.1;
    @media (max-width: 960px) {
      font-size: 4.4rem;
      line-height: 1.1;
    }
    @media (max-width: 640px) {
      font-size: 3.4rem;
      line-height: 1.1;
    }
  }

  .pricing-details{
    border-top: 1px solid #E8D6D6;
    border-bottom: 1px solid #E8D6D6;
    color: #20323f;
    padding: 2rem 0 1rem 0;
    ul{
      list-style: none;
      display: flex;
      flex-direction: column;
      li{
        display: flex;
        font-size: 1.05rem;
        @media (max-width: 1200px) {
          font-size: 1.5rem;
        }
        @media (max-width: 960px) {
          font-size: 2rem;
        }
        div{
          &:first-child{
            flex-grow: 1; 
          }
          &:nth-child(2){
         
            text-align: right;
            color: #e72076;
            font-weight: bold;
          }
        }
        
        &.txt-large{
          font-size: 2rem;
          font-weight: 800;
          padding-top: 1.6rem;
          margin-top: 1.6rem;
          border-top: 1px solid #E8D6D6;
          &.txt-updated{
            font-size: 2rem;
            border-top: none;
            padding-top: 0;
            margin-top: 0;
          }
        }
        &.txt-was{
          font-size: 1.5rem;
          font-weight: 800;
          padding-top: 1.6rem;
          margin-top: 1.6rem;
          border-top: 1px solid #E8D6D6;
          display: none;
          &.show{
            display: inline-flex;
          }
        }
      }
    }
  }

  .pricing-details-bill{
   
    border-bottom: 1px solid #E8D6D6;
    color: #20323f;
    padding: 0;
    .outter{
      list-style: none;
      display: flex;
      flex-direction: column;
      .item{
        display: flex;
        font-size: 1.05rem;
        @media (max-width: 1200px) {
          font-size: 1.5rem;
        }
        @media (max-width: 960px) {
          font-size: 2rem;
        }
        div{
          &:first-child{
            flex-grow: 1; 
          }
          &:nth-child(2){
            width: 35%;
            text-align: right;
            color: #e72076;
            font-weight: bold;
          }
        }
        
        &.txt-large{
          font-size: 2rem;
          font-weight: 800;
          border-top: 1px solid #E8D6D6;
          padding: 10px 0;
          &.txt-updated{
            font-size: 2rem;
            border-top: none;
            padding-top: 0;
            margin-top: 0;
          }
        }
        &.txt-was{
          font-size: 1.5rem;
          font-weight: 800;
          padding-top: 1.6rem;
          margin-top: 1.6rem;
          border-top: 1px solid #E8D6D6;
          display: none;
          &.show{
            display: inline-flex;
          }
        }
      }
    }
  }
  .helper-txt{
    width:100% !important;
    display: block !important;
    position: relative !important;
  }
  .terms-wrap-chk {
    margin-bottom: 0 !important;
  }
}
 

.discount-notification.show{
  padding: 1rem;
  background-color: #fbffd7;
  margin-bottom: 1.5rem;
  p{
    font-size: 1rem;
    line-height: 1.3;
  }
}


.loading-wrapper {
  display: none;
  width:100vw;
  height:100vh;
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 100;
  background-color: rgba(0,0,0,0.8);
}

.loader {
  border: 16px solid #fff;
  border-top: 16px solid #e72076;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position:absolute;
  top:calc(50% - 60px);
  left:calc(50% - 60px);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

body.loaded {
  .loading-wrapper {
    display: block;
  }
  #mobile-burger-btn {
    display: none;
  }
}
