.pagination {
    margin: 0;
    position: relative;
    text-align: center;
    width: 100%;
    padding: 6px 0 1px;
    border-top: 1px solid red;

    >li {
        display: none;
        // display: inline-block;

        &.previous,
        &.next,
        &.active,
        &.active+li {
            display: inline-block;


        }




        &.previous,
        &.next {
            position: absolute;

            a {
                padding: 6px 0;
            }
        }

        &.previous {
            left: 0;
        }

        &.next {
            right: 0;
        }

        &.active {
            a {

                &,
                &:hover {
                    background: none;
                    color: red;
                }
            }
        }

        &.disabled {
            a {
                opacity: .4;
            }
        }

        >a {
            border: none;

            &,
            &:hover {
                background: none;
            }
        }
    }

    .arrow {
        color: red;
        font-size: 26px;
        line-height: 0;
        vertical-align: -2px;
    }

    .previous .arrow {
        margin-right: 5px;
    }

    .next .arrow {
        margin-left: 5px;
    }
}