#homeslide{
  @media (max-width: 800px) {
    background-color: #fee5f2;
    padding-bottom: 2rem;
  }
}

.slideshow {
  background-color: #fee5f2;
  margin-bottom: 2rem;
  margin-top: .3rem;
  z-index: 100;


  &:after {
    @apply absolute top-0 left-0 bg-white w-full z-10;
    height: 4.5rem;
    content: " ";
    @media (max-width: 800px) {
      display: none;
    }
  }
  &:before {
    @apply absolute bottom-0 left-0 bg-white w-full z-10;
    height: 4.5rem;
    content: " ";
    @media (max-width: 800px) {
      display: none;
    }
  }
  .slide {
    position: relative;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    .txt-wrap {
      padding-top: 14.618%;
      padding-bottom: 13.29%;
      position: relative;
      z-index: 100;
      width: 60%;
      &.medium{
        width: 38%;
        @media (max-width: 800px) {
          width: 100%;
        }
      }
      &.small{
        width: 36%;
        @media (max-width: 800px) {
          width: 100%;
        }
      }
      @media (max-width: 800px) {
        width: 100%;
        padding: 7rem 0 5rem 0;
      }
      h2 {
        font-family: $title-font-family;
        font-size: 4.5rem;
        color: #20323f;
        font-weight: 700;
        margin-bottom: 2rem;
        z-index: 200;
        line-height: 1.09;
        @media (max-width: 800px) {
          font-size: 6.5rem;
        }
        @media (max-width: 640px) {
          font-size: 4.5rem;
        }
        &.medium{
          font-size: 3.5rem;
          @media (max-width: 800px) {
            font-size: 5.5rem;
            margin-bottom: 1rem;
          }
          @media (max-width: 640px) {
            font-size: 3.5rem;
          }
        }
        &.small{
          font-size: 2.5rem;
          @media (max-width: 800px) {
            font-size: 4.5rem;
            margin-bottom: 1rem;
          }
          @media (max-width: 640px) {
            font-size: 2.5rem;
          }
        }

        &:before {
          z-index: 150;
          position: absolute;
          left: -4rem;
          top: -2rem;
          width: 7rem;
          height: 7rem;
          background-image: url("/assets/img/texture-small.png");
          background-size: .8rem;
          background-repeat: repeat;
          display: inline-block;
          content: " ";
        }
      }
    }
    .img-wrap {
      right: -5.645%;
      border-radius: 1rem;
      overflow: hidden;
      display: flex;
      width: 66%;
      height: 100%;
      margin: .6rem 0;
      box-shadow: 0 0 1rem 0 rgba(0,0,0,0.15);
      position: absolute;
      z-index: 50;
      @media (max-width: 800px) {
        width: 100%;
        height: 60vw;
        position: relative;
        right: auto;
        margin-bottom: 50px;
      }
      &:before {
        content: " ";
        position: absolute;
        opacity: 0.66;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 3%,
          rgba(255, 255, 255, 0) 100%
        );
        width: 444px;
        height: 796px;
        top: -25px;
        left: -100px;
      }
      img{

      }
    }
  }
}

.arrow-wrap {
  bottom: 1rem;
  z-index: 2000;
  display: none;
  img{
    height: 100%;
  }
  @media (max-width: 1000px) {
    display: none;
  }
}

.slide-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  top: -4rem;
  position: relative;
  @media (max-width: 800px) {
    top: -6rem;
  }
  .slide-pagination {
    width: fit-content;

    ul.slick-dots {
      position: relative;
      bottom: 4px;
      line-height: 0;
      padding: 0 15px;
      margin: 0;
      li {
        padding: 0;
        margin: 0;
        display: inline-block;
        position: relative;
        background: none;

        button {
          position: absolute;
          top: 6px;
          left: 6px;
          height: 1px;
          width: 1px;
          border-radius: 50%;
          overflow: hidden;
          border: none;
          background: #20323f;
          margin: 0;
          padding: 5px;
          cursor: pointer;

          &:before {
            display: none;
          }
        }

        &.slick-active {
          button {
            background: #e72076;
          }
        }
      }
    }
  }

  #slide-prev,
  #slide-nxt {
    width: auto;
    height: 20px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    color: #616161;

    .fa-arrow-left,
    .fa-arrow-right {
      &::before {
        font-size: 22px;
      }
    }
  }
}
