.map {
  height: 400px;
}


.branches {
  .child {
    border-top: 2px solid #cfcfcf;

    &:nth-child(1) {
      border: 0;
    }
  }
  &.branches-snippet{
    .child {
      border-top: none;

      .contact-item-parent {
          .contact-item-child{
            h3{
              @apply font-bold;
              font-family: $title-font-family;
              font-size: 1.4rem;
              line-height: 1.3;
              @media (max-width: 960px) {
                font-size: 2.6rem;
              }
              @media (max-width: 768px) {
                font-size: 3rem;
              }
            }
          }
      }
    }
  }
}
