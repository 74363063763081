html {
  box-sizing: border-box;
  font-size: 1vw;

  @media (max-width: 640px) {
    font-size: 2vw;
  }
  @media (min-width: 1700px) {
    font-size: 17px;
  }
}

body {
  font-family: $standard-font-family;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  margin: 0;
  background-color: #20323f;
  color: #787878;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

strong {
  font-weight: 700;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.page-wrap {
  position: relative;
  overflow: hidden;

  .page-inner-wrap {
    margin: 0 auto;
    position: relative;
    right: 0;
    z-index: 100;
    transition: right 0.3s ease;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    background-color: white;

    &.push {
      right: 354px;
      box-shadow: -1px 0px 14px 1px rgba(0, 0, 0, 0.6);
    }
  }

  a {
    position: relative;
    top: 0;

    &:active {
      top: 1px;
    }
  }
}



.center {
  text-align: center;
}

.general {
  p {
    margin-bottom: 1.5em;
  }
}

.mobile-nav-items {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  max-width: 354px;
  padding: 46px 60px;

  .top {
    margin-bottom: 25px;

    img {
      max-width: 120px;
    }
  }

  ul {
    li {
      padding: 14px 0;
      border-bottom: 1px solid #474954;

      &:last-child,
      &:nth-last-child(2) {
        border-bottom: none;
      }

      a {
        color: #fff;
        font-family: $title-font-family;
        font-size: 16px;
        text-transform: uppercase;

        &[href="/order-now"] {
          background-color: #e72076;
          color: white;
          border-radius: 100px;
          width: 100%;
          display: block;
          padding: 12px;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
}

.small-container {
  max-width: 984px;
}

.featured-header {
  border-radius: 1rem;
  background: #fee5f2;
  padding: 3.6rem;
  margin-bottom: 5rem;
  margin-top: 3rem;

  h2 {
    @apply font-bold;
    font-family: $title-font-family;
    color: #e72076;
    font-size: 2.5rem;
    line-height: 1.3;
    @media (max-width: 960px) {
      font-size: 3.5rem;
      line-height: 1.1;
      margin-bottom: 4rem;
    }
  }

  h1 {
    @apply font-bold;
    font-family: $title-font-family;
    color: #20323f;
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 2.7rem;
    line-height: 1.1;
    @media (max-width: 960px) {
      font-size: 4.4rem;
      line-height: 1.1;
    }
    @media (max-width: 640px) {
      font-size: 3.4rem;
      line-height: 1.1;
    }
  }
}

.pricing-panel {
  box-shadow: 0 0 3px -1px rgba(0,0,0,0.35);
  border-radius: 1rem;
  padding: 1.6rem;
  text-align: center;
  height: 100%;

  @media (max-width: 768px) {
    margin-bottom: 8rem;
  }

  @media (max-width: 640px) {
    margin-bottom: 4rem;
  }

  h3 {
    @apply font-bold;
    font-family: "filson-soft";
    font-size: 1.8rem;
    color: #e72076;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  h4 {
    @apply font-bold;
    font-family: $title-font-family;
    font-size: 3.4rem;
    color: #20323f;
    text-align: center;
    line-height: 1;

    @media (max-width: 960px) {
      font-size: 5.4rem;
    }

    @media (max-width: 768px) {}

    @media (max-width: 640px) {}
  }

  span {
    @apply font-bold block;
    font-family: $title-font-family;
    font-size: 1.1rem;
    color: #20323f;
    text-align: center;
    line-height: 1.3;

    @media (max-width: 960px) {
      font-size: 2.1rem;
    }

    @media (max-width: 768px) {}

    @media (max-width: 640px) {}
  }

  ul {
    margin: 0 25px;
    padding: 0;
    list-style: none;

    li {
      color: #20323f;
      font-size: 1rem;

      @media (max-width: 960px) {
        font-size: 1.7rem;
      }

      @media (max-width: 768px) {}

      @media (max-width: 640px) {}

      &:first-child {
        font-weight: bold;
        font-family: $title-font-family;
        font-size: 1.1rem;

        @media (max-width: 960px) {
          font-size: 2.1rem;
        }

        @media (max-width: 768px) {}

        @media (max-width: 640px) {}
      }
    }
  }

  .btm {
    margin-top: 1.8rem;
    border-top: 2px solid #e1e1e1;
    padding: 1.8rem 0;
    &.orderpage{
      @media (min-width: 640px) and (max-width: 768px) {
        border-top: none;
        border-left: 2px solid #e1e1e1;
      }
    }
  }


  &-minor {
    padding: 2.5rem 2rem;
    box-shadow: 0 0 7px -1px rgba(0, 0, 0, 0.35);
    border-radius: 1rem;
    text-align: center;
    width: 39%;

    @media (max-width: 960px) {
      padding: 5.5rem 3rem;
      width: 47%;
    }

    @media (max-width: 640px) {
      padding: 5.5rem 4rem;
      width: 95%;
    }

    h3 {
      font-size: 1.3rem;

      @media (max-width: 960px) {
        font-size: 2.3rem;
      }

      @media (max-width: 768px) {}

      @media (max-width: 640px) {}
    }

    ul {
      li {
        color: #20323f;
        margin: .8rem 0;

        @media (max-width: 960px) {
          font-size: 1.8rem;
        }
      }
    }
  }
}

.hr {
  width: 100%;
  height: 2px;
  background-color: #d7d7d7;
}

.general-txt-wrap {
  h2 {
    font-family: $title-font-family;
    font-weight: bold;
    font-size: 2.3rem;
    color: #e72076;
    line-height: 1.3;
    @media (max-width: 960px) {
      font-size: 3rem;
      line-height: 1.3;
    }
  }

  h3 {
    font-family: $title-font-family;
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.3;
    @apply .text-navy mb-4;

    @media (max-width: 960px) {
      font-size: 3rem;
      line-height: 1.3;
    }
  }

  ul {
    list-style-type: disc;
    margin: 0 0 1.5em;

    li {
      margin: 0.5em 1.5em;
    }
  }

  a {
    @apply text-pink font-bold underline;
  }
}



.text_and_image {

  ul {
    line-height: 2em;
    padding: 0;

    li {
      list-style: none;
      position: relative;
      padding: 0 0 0 30px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        height: 10px;
        width: 10px;
        border: 1px solid #E72076;
        border-width: 2px 2px 0 0;
        transform: rotate(45deg);
      }
    }
  }
}

.trustpilot {
  padding: 4rem 0;
}


#paymentform{
  &.hide {
    display: none;
  }
  &.show {
    display: block;
  }
}

#paymentIframe{
  &.hide {
    display: none;
  }
  &.show {
    display: block;
  }
}

.continer-pay-a-bill{
  padding: 0 !important;
  width: 100% !important;
}

