$title-font-family: "filson-soft";
$standard-font-family: "proxima-nova";

$title-font-book: 400;
$title-font-medium: 500;
$title-font-heavy: 700;

$standard-font-regular: 400;
$standard-font-medium: 500;
$standard-font-semibold: 600;
$standard-font-bold: 700;

$container: 1200;
$container_small: 1200;

$yellow: #ffe700;
$gold: $yellow;
$red: #b6051a;
$blue: #20609b;
$lightblue: #069afe;

$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;
$max-width: 100%;

$imgpath: "../../../../assets/img/";
