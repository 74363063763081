.title {
  @apply font-bold;
  font-family: $title-font-family;
  //font-size: 50px;
  font-size: 2.5rem;
  line-height: 1.1;
  @media (max-width: 960px) {
    font-size: 4.4rem;
    line-height: 1.1;
  }
  @media (max-width: 640px) {
    font-size: 3.4rem;
    line-height: 1.1;
  }
}

.title-minor {
  @apply font-bold;
  font-family: $title-font-family;
  font-size: 2rem;
  line-height: 2;
  @media (max-width: 960px) {
    font-size: 4rem;
  }
  @media (max-width: 640px) {
    font-size: 3rem;
  }
}

.subTitle {
  @apply font-bold;
  font-family: $title-font-family;
  font-size: 2.5rem;
  line-height: 1.3;
  @media (max-width: 960px) {
    font-size: 3.5rem;
    line-height: 1.1;
    margin-bottom: 1rem;
  }
}

.subTitle-minor {
  @apply font-bold;
  font-family: $title-font-family;
  font-size: 30px;
  line-height: 1.3;
}

.smallSubTitle {
  @apply font-bold;
  font-family: $title-font-family;
  font-size: 1.7rem;
  line-height: 1.3;
  @media (max-width: 960px) {
    font-size: 2.6rem;
  }
  @media (max-width: 768px) {
    font-size: 3rem;
  }
}

.lgphonenumner {
  @apply font-bold;
  font-family: $title-font-family;
  font-size: 2rem;
  line-height: 1.3;
  @media (max-width: 960px) {
    font-size: 4rem;
  }
  @media (max-width: 768px) {
    font-size: 6rem;
  }
  @media (max-width: 640px) {
    font-size: 4rem;
  }
}

.smallSubTitle-minor {
  @apply font-bold;
  font-family: $title-font-family;
  font-size: 1.15rem;
  line-height: 1.3;
  @media (max-width: 1024px) {
    font-size: 1.5rem;
  }
  @media (max-width: 960px) {
    font-size: 2.5rem;
  }
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
  @media (max-width: 640px) {
    font-size: 1.6rem;
  }
}

.title-font-family {
  font-family: $title-font-family;
}

p {
  font-size: 1.05rem;
  line-height: 1.7rem;
  @media (max-width: 1500px) {
    font-size: 1.3rem;
  }
  @media (max-width: 1100px) {
    font-size: 1.5rem;
    line-height: 1.9rem;
  }
  @media (max-width: 960px) {
    font-size: 2rem;
    line-height: 2.9rem;
  }
  @media (max-width: 768px) {
    font-size: 2.5rem;
    line-height: 3.4rem;
  }
  @media (max-width: 640px) {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

.customerstitle {
  .subTitle {
    @media (max-width: 446px) {
    }
  }
  .heartimage {
    height: 2.5rem;
    margin: 0.5rem 0.8rem 0;
    @media (max-width: 960px) {
      height: 4rem;
    }
    @media (max-width: 640px) {
    }
  }
}
